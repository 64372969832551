import { createStore } from 'vuex'

export interface IState{
  totalSquare: number,
  floorsCount: number,
  firsFloorHeight: number,
  secondFloorHeight: number,
  material: string,
  totalWindowsSquare: number,
  totalInnerDoors: number,
  base: {
    outerLine: {
      a: number,
      b: number,
      c: number,
      l: number
    },
    innerLine: {
      a: number,
      b: number,
      c: number,
      l: number
    },
   concreteCount: number
  },
  showPopup: {
    show: boolean,
    componentIndx: number
  },
  currentWindow: string

}

export default createStore<IState>({
  state: {
    totalSquare: 120,
    floorsCount: 1,
    firsFloorHeight: 3,
    secondFloorHeight: 3,
    material: 'Кирпич',
    totalWindowsSquare: 12,
    totalInnerDoors: 5,
    base: {
      outerLine: {
        a: 400,
        b: 200,
        c: 300,
        l: 40
      },
      innerLine: {
        a: 200,
        b: 200,
        c: 300,
        l: 35
      },
      concreteCount: 11.5
    },
    showPopup: {
      show: false,
      componentIndx: 0
    },
    currentWindow: 'Main'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
