<template>
    <div class="card-container">
      
        <slot></slot>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.card-container{
    width: 100%;
    min-width: 300px;
    border: 0px solid green;
}

</style>